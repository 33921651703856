import React from 'react';
import Label from '../Label/Label.styles';
import { TextAreaStyles } from './TextArea.styles';
const TextArea = ({ onKeyDown = (e) => {
    if (e.key.toLowerCase() === 'enter') {
        e.preventDefault(); // Stops weird behaviour
        e.currentTarget.value = e.currentTarget.value + '\r\n'; // Allows for line return
    }
}, ...props }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Label, { htmlFor: props.id },
            props.label,
            " ",
            props.required ? React.createElement("span", null, "*") : null),
        React.createElement(TextAreaStyles.Input, { ...props, isValid: props.isValid, onKeyDown: onKeyDown })));
};
export default TextArea;

import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';
export const InputStyles = css `
  display: inline-block;
  border-radius: 3px;
  border: 1px solid ${rgba(brand.grey.grey55, 0.75)};
  box-sizing: border-box !important;
  ${fonts.DaxPro.Regular};
  font-size: 18px;
  color: ${brand.black};
  height: 50px;
  letter-spacing: -0.02em;
  line-height: 1em;
  margin: 0;
  opacity: 1;
  padding: 18px;
  text-transform: none;
  transition: opacity 0.3s ease-out;
  width: auto;

  &::placeholder {
    color: ${rgba(brand.black, 0.5)};
  }

  &:disabled {
    opacity: 0.4;
  }

  ${({ isValid }) => isValid === true || isValid === undefined
    ? null
    : css `
          border: 1px solid red;
        `}
`;
const Input = styled.input `
  ${InputStyles};

  [data-show-clear='true'] & {
    padding-right: 35px;
    width: 100%;
  }
`;
const ClearInput = styled.div `
  position: absolute;
  top: 50%;
  right: 10px;
  height: 15px;
  transform: translateY(-50%);
  width: 15px;
  cursor: pointer;
`;
const Container = styled.div `
  position: relative;
  flex-grow: 1;

  &[data-show-clear='false'] {
    display: contents;
  }
`;
export default {
    Input,
    ClearInput,
    Container,
};

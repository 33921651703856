import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import styled from 'styled-components';
const Label = styled.label `
  display: block;
  font-size: 16px;
  ${fonts.DaxPro['Bold']};
  color: ${brand.grey.grey20};
  margin-bottom: 6px;
`;
const ErrorLabel = styled.span `
  display: block;
  font-size: 14px;
  ${fonts.DaxPro['Bold']};
  color: ${brand.red.base};
  margin-bottom: 6px;
`;
export const LabelStyles = {
    Label,
    ErrorLabel,
};
export default Label;

import brand from '@helpers/brand';
import { fonts } from '@helpers/fonts';
import { Device, from } from '@helpers/media';
import styled, { css } from 'styled-components';
import { TextAreaStyles } from '../TextArea/TextArea.styles';
import TextboxStyles from '../Textbox/Textbox.styles';
const Element = styled.div `
  display: block;
  width: 100%;
  margin-bottom: 20px;

  ${({ size }) => {
    switch (size) {
        default:
        case 'default':
            return css `
          max-width: 100%;
        `;
        case 'medium':
            return css `
          @media ${from(Device.TabletLarge)} {
            max-width: 387px;
          }
        `;
        case 'small':
            return css `
          max-width: 232px;

          @media ${from(Device.TabletLarge)} {
            max-width: 248px;
          }
        `;
    }
}}

  label {
    display: block;
    font-size: 16px;
    line-height: 1.2;
    ${fonts.DaxPro['Medium']};
    color: ${brand.black};
    margin-bottom: 8px;

    @media ${from(Device.TabletLarge)} {
      font-size: 18px;
    }
  }

  ${TextboxStyles.Input}, ${TextAreaStyles.Input} {
    display: block;
    width: 100%;

    + ${TextboxStyles.Input} {
      margin-top: 8px;
    }
  }
`;
export default {
    Element,
};

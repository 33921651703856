import debounce from 'lodash/debounce';
import React, { useRef, useState } from 'react';
import Label from '../Label/Label.styles';
import TextboxStyles from './Textbox.styles';
import { ReactComponent as Reset } from '@img/icons/close.svg';
const Textbox = ({ onKeyDown = (e) => {
    if (e.key.toLowerCase() === 'enter') {
        e.preventDefault(); // Stops weird behaviour
    }
}, showClearButton = false, ...props }) => {
    const [value, setvalue] = useState(props.value || '');
    const debouncedChanged = useRef(debounce((v) => {
        if (!props.onChangeDebounced) {
            return;
        }
        props.onChangeDebounced(v);
    }, 500));
    const onChange = (e) => {
        setvalue(e.currentTarget.value);
        if (props.onChange) {
            props.onChange(e);
        }
        if (props.onChangeDebounced) {
            debouncedChanged.current(e.currentTarget.value);
        }
    };
    return (React.createElement(TextboxStyles.Container, { "data-show-clear": showClearButton },
        props.label && (React.createElement(Label, { htmlFor: props.id },
            props.label,
            " ",
            props.required ? React.createElement("span", null, "*") : null)),
        React.createElement(TextboxStyles.Input, { ...props, value: value, isValid: props.isValid, onChange: (e) => onChange(e), onKeyDown: onKeyDown }),
        showClearButton && (React.createElement(TextboxStyles.ClearInput, { onClick: () => {
                setvalue('');
                props.onClearButton && props.onClearButton();
            } },
            React.createElement(Reset, null)))));
};
export default Textbox;

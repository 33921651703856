import brand from '@helpers/brand';
import { Device, from } from '@helpers/media';
import Heading from '@stories/Components/Global/Typography/Heading';
import HeadingStyles from '@stories/Components/Global/Typography/Heading.styles';
import { ReactComponent as SvgOverlay } from '@img/banner-overlay-solid.svg';
import styled, { css } from 'styled-components';
import { BreadcrumbsStyles } from '../../Meta/Breadcrumbs/Breadcrumbs.styles';
import { Grid } from '@helpers/grid';
import { ButtonStyles } from '@stories/Components/Buttons/Button/Button.styles';
import { fonts } from '@helpers/fonts';
import Paragraph from '@stories/Components/Global/Typography/Paragraph';
import SelectStyles from '@stories/Components/Forms/Select/Select.styles';
import { TextAreaStyles } from '@stories/Components/Forms/TextArea/TextArea.styles';
import { LoaderStyles } from '@stories/Components/Misc/Loader/Loader.styles';
import FormFieldStyles from '@stories/Components/Forms/FormField/FormField.styles';
const Wrapper = styled.section `
  min-height: 300px;
  position: relative;
  overflow: hidden;

  @media ${from(Device.Tablet)} {
    min-height: 450px;
  }

  @media ${from(Device.TabletLarge)} {
    min-height: 768px;
  }
`;
const ImageOverlay = styled(SvgOverlay) `
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  color: ${brand.primary.alt};
  transform: scaleY(-1);

  @media ${from(Device.TabletLarge)} {
    height: 768px;
  }
`;
const ContentWrapper = styled.div `
  position: relative;
  z-index: 1;

  ${BreadcrumbsStyles.Wrapper} {
    width: 100%;

    @media ${from(Device.TabletLarge)} {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  ${BreadcrumbsStyles.Inner} {
    li {
      a {
        color: ${brand.white};
      }
    }
  }
`;
const ContentWrapperInner = styled.div `
  display: flex;
  margin: 70px 0px 40px 0px;
  width: 100%;

  @media ${from(Device.Tablet)} {
    margin: 70px 0px;
  }

  @media ${from(Device.TabletLarge)} {
    align-items: flex-end;
  }

  ${Grid.Default} {
    width: 100%;
  }
`;
const ContentInner = styled.div `
  grid-column: span 12;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${from(Device.MobileLarge)} {
    grid-column: 1 / span 10;
  }

  @media ${from(Device.TabletLarge)} {
    grid-column: 1 / span 3;
    padding-top: 100px;
  }

  ${HeadingStyles.Heading} {
    color: ${brand.white};
    text-transform: none;
  }
`;
const Title = styled(Heading) ``;
const Intro = styled.div `
  color: ${brand.white};
  font-size: 16px;
  line-height: 28px;
`;
const ContactBox = styled.div `
  margin-top: 30px;
  background-color: ${brand.primary.dark};
  padding: 15px 25px;
`;
const ContactTitle = styled.p `
  ${fonts.DaxPro.Bold};
  font-size: 24px;
  line-height: 28px;
  color: ${brand.white};
`;
const ContactItem = styled(Paragraph) `
  color: ${brand.white};
  margin-bottom: 0;
  word-break: break-all;

  a {
    color: ${brand.white};

    &:hover {
      color: ${brand.white};
    }
  }
`;
const FormInner = styled.div `
  background-color: ${brand.white};
  border-top: 12px solid ${brand.primary.alt};
  grid-column: span 12;
  padding: 24px;

  @media ${from(Device.TabletLarge)} {
    padding: 42px;
  }

  @media ${from(Device.TabletLarge)} {
    grid-column: 6 / span 7;
  }

  @media ${from(Device.DesktopLarge)} {
    padding: 60px 130px 60px;
  }

  ${LoaderStyles.Wrapper} {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
`;
const FormGrid = styled.div `
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 16px;
  gap: 16px;

  @media ${from(Device.TabletLarge)} {
    gap: 32px;
  }

  ${FormFieldStyles.Element} {
    margin-bottom: 0px;
  }
`;
const Field = styled.div `
  display: block;
  margin: 0;
  width: 100%;

  ${({ center }) => center
    ? css `
          text-align: center;
        `
    : null}

  ${({ wide }) => wide
    ? css `
          grid-column: span 12;
        `
    : css `
          grid-column: span 12;

          @media ${from(Device.Tablet)} {
            grid-column: span 6;
          }
        `}

  ${SelectStyles.Element} {
    min-width: 100px;
    width: 100%;
  }

  ${TextAreaStyles.Input} {
    width: 100%;
  }

  > ${ButtonStyles.Button} {
    width: 100%;
    max-width: 260px;
  }
`;
const Error = styled.span `
  display: block;
  color: #c70704;
  ${fonts.DaxPro.Regular};
  font-size: 14px;
  line-height: 20px;
  margin: 8px 0 0;
  padding: 0;
  text-align: ${({ center }) => (center ? 'center' : 'left')};
`;
const ConfirmationWrapper = styled.div `
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const ContactUsStyles = {
    ContactBox,
    ContactItem,
    ContactTitle,
    ContentInner,
    ContentWrapper,
    ContentWrapperInner,
    Error,
    Field,
    FormGrid,
    FormInner,
    ImageOverlay,
    Intro,
    Title,
    Wrapper,
    ConfirmationWrapper,
};
